<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" fixed>
      <v-toolbar flat color="transparent">
        <v-toolbar-title>Account</v-toolbar-title>
      </v-toolbar>

      <v-divider></v-divider>

      <v-list
        subheader
      >
        <v-list-item :to="toDashboard">
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item :to="toFavorite">
          <v-list-item-content>
            <v-list-item-title>Favorite</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item
            v-for="submenu in item.items"
            :key="submenu.title"
            :to="submenu.to"
          >
            <v-list-item-content>
              <v-list-item-title v-text="submenu.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>

      <v-divider></v-divider>

      <v-list
        two-line
        subheader
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Donate</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

    </v-navigation-drawer>
    <v-card
      color="grey lighten-4"
      flat
      height="200x"
      tile
    >
      <v-toolbar dense color="lime">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>Priblic</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-heart</v-icon>
        </v-btn>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data () {
    return {
      drawer: false,
      toDashboard: '/',
      toFavorite: '/favorite',
      items: [
        {
          action: 'mdi-ticket',
          active: false,
          items: [
            /*, selected: [0] */
            { title: 'Briefing', to: '/briefing' },
            { title: 'Economic', to: '/economicnews' }
          ],
          title: 'News'
        },
        {
          action: 'mdi-silverware-fork-knife',
          items: [
            { title: 'Stock', to: '/stock' },
            { title: 'Coin', to: '/coin' }
          ],
          title: 'Market'
        },
        {
          action: 'mdi-silverware-fork-knife',
          items: [
            { title: 'About', to: '/about' }
          ],
          title: 'About'
        }
      ]
    }
  },
  methods: {
  }
}
</script>
