<template>
  <v-container fluid grid-list-md>
    <v-layout>
      <v-flex xs5>
        <v-text-field v-model="title" label="title"></v-text-field>
      </v-flex>
      <v-flex xs5>
        <v-text-field v-model="content" label="content"></v-text-field>
      </v-flex>
      <v-flex xs2>
        <v-btn color="success" @click="post">post</v-btn>
      </v-flex>
    </v-layout>
    <v-data-iterator
      :items="items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="search"
      :sort-by="sortBy.toLowerCase()"
      :sort-desc="sortDesc"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.title"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-card>
              <v-card-title class="subheading font-weight-bold">
                {{item.title}}
              </v-card-title>

              <v-divider></v-divider>

              <v-list dense>
                <v-list-item-content v-text="item.content"></v-list-item-content>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-slot:footer>
        <v-row
          class="mt-2"
          align="center"
          justify="center"
        >
          <span class="grey--text">Items per page</span>
          <v-menu offset-y>
            <template v-slot:activator="{on, attrs}">
              <v-btn
                dark
                text
                color="primary"
                class="ml-2"
                v-bind="attrs"
                v-on="on"
              >
                {{itemsPerPage}}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }} </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <span
            class="mr-4 grey--text"
          >
            Page {{ page }} of {{ numberOfPages }}
          </span>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>
export default {
  data () {
    return {
      itemsPerPageArray: [4, 8, 12],
      search: '',
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 4,
      sortBy: 'title',
      items: [
        {
          title: 'title1',
          content: 'content'
        },
        {
          title: 'title2',
          content: 'content2'
        },
        {
          title: 'title3',
          content: 'content'
        },
        {
          title: 'title4',
          content: 'content2'
        },
        {
          title: 'title5',
          content: 'content'
        },
        {
          title: 'title6',
          content: 'content2'
        },{
          title: 'title7',
          content: 'content'
        },
        {
          title: 'title8',
          content: 'content8'
        },
        {
          title: 'title9',
          content: 'content9'
        },
        {
          title: 'title10',
          content: 'content10'
        },
        {
          title: 'title11',
          content: 'content11'
        },
        {
          title: 'title12',
          content: 'content12'
        }
      ],
      title: '',
      content: ''
    }
  },

  mounted () {
  },
  computed: {
    numberOfPages () {
      return Math.ceil(this.items.length / this.itemsPerPage)
    }
  },
  methods: {
    post () {
      this.items.push({ title: this.title, content: this.content })
      this.title = ''
      this.content = ''
    },
    get () {

    },
    update () {

    },
    del () {

    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    }
  }
}
</script>
