<template>
  <div>
    Home...
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  data () {
    return {
      message: 'alert..! : ' + new Date() + '발생',
      seen: true,
      todos: [
        { no: 1, text: '11111' },
        { no: 2, text: '22222' },
        { no: 3, text: '33333' },
        { no: 3, text: '33333' }
      ]
    }
  },
  methods: {
    hide () {
      this.seen = false
    }
  }
}
</script>
