import Vue from 'vue'
import VueRouter from 'vue-router'

// Dashboard
import Dashboard from '../views/dashboard/Dashboard.vue'
import Favorite from '../views/dashboard/Favorite.vue'

// Market
import Stock from '../views/market/Stock.vue'
import Coin from '../views/market/Coin.vue'

// News
import Briefing from '../views/news/Briefing.vue'
import EconomicNew from '../views/news/EconomicNews.vue'

// Common
import PageNotFound from '../views/common/PageNotFound'

import Home from '../views/Home.vue'
import About from '../views/About/About.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/favorite',
    name: 'Favorite',
    component: Favorite
  },
  {
    path: '/stock',
    name: 'stock',
    component: Stock
  },
  {
    path: '/coin',
    name: 'Coin',
    component: Coin
  },
  {
    path: '/briefing',
    name: 'Briefing',
    component: Briefing
  },
  {
    path: '/economicnews',
    name: 'EconomicNew',
    component: EconomicNew
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '*',
    name: 'p404',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
